import { ReactNode, useEffect, useState } from "react";
import { BACKGROUND_IMG, BACKGROUND_IMG_MOBILE } from "../../constant/constant";
type Props = {
    children: ReactNode;
  };
function LazyBackground({children}: Props) {
    const [source, setSource] = useState("");
    const [isMobile, setIsMobile] = useState(false);
 
//choose the screen size 
const handleResize = () => {
  if (window.innerWidth <= 1038) {
      setIsMobile(true);
  } else {
      setIsMobile(false);
  }
}

    useEffect(() => {
        const img = new Image();
        img.src = isMobile ? BACKGROUND_IMG_MOBILE : BACKGROUND_IMG;
        img.onload = () => setSource(img.src);
        window.addEventListener("resize", handleResize);
    }, [isMobile])

    return(
        <div style={{backgroundImage: `url(${source})`}} className="display-flex display-flex-row content-page page-container img-transformation-container">{children}</div>
    )
}

export default LazyBackground;