import { ScreenProps } from "./types";
import facebook from "../assets/images/facebook.svg";
import tiktok from "../assets/images/tik-tok.svg";

//WEB CONFIG CONST
export const ANIME_PAGE_TITLE: ScreenProps = {
    mainTitle: "Anime",
    subTitle: "Transformation Photo"
}
export const imageWidth = 512;
export const imageHeight = 512;

//api call constant
export const api_url = 'https://api_gateway.img2anime.click';
export const api_path_token = "/token";
export const api_path_request_job = "/genimg/v1/img2img";
export const api_path_request_job_progress = "/genimg/v1/jobprogress";

//host name constant
export const WEB_URL = 'https://img2anime.click/';
//web url parameter
export const url_parameter = 'img2anime';

export const token_params = {
    "username": "apigwu",
    "pass_hash": "GUjcR1MGf589697276effb09"
}

export const googleAdSlot = {
    leftAd: '7398259278',
    rightAd: '6040165329',
    bottomAd: '3345291598',
    topAd: '7092964918',
    queueModel: '8381048707'
}
export const websiteHashTag = "#img2anime";
export const QUICK_OVERVIEW_CONTENTS = [
    {title:"quickOverviewTitle1",content: "quickOverviewContent1", img: "https://nginx.img2anime.click/static/demo/demo21.webp", img_alt:"demo art 21"},
    {title:"quickOverviewTitle2",content: "quickOverviewContent2", img: "https://nginx.img2anime.click/static/demo/demo20.webp", img_alt:"demo art 20"},
    {title:"quickOverviewTitle3",content: "quickOverviewContent3", img: "https://nginx.img2anime.click/static/demo/demo19.webp", img_alt:"demo art 19"}
]
export const BACKGROUND_IMG = "https://nginx.img2anime.click/static/background_img2anime.webp";
export const BACKGROUND_IMG_MOBILE = "https://nginx.img2anime.click/static/background_img2anime_mobile.webp";
export const GALLERY_IMGS = [
    "https://nginx.img2anime.click/static/demo/demo1.webp",
    "https://nginx.img2anime.click/static/demo/demo2.webp",
    "https://nginx.img2anime.click/static/demo/demo3.webp",
    "https://nginx.img2anime.click/static/demo/demo4.webp",
    "https://nginx.img2anime.click/static/demo/demo5.webp",
    "https://nginx.img2anime.click/static/demo/demo6.webp",
    "https://nginx.img2anime.click/static/demo/demo7.webp",
    "https://nginx.img2anime.click/static/demo/demo8.webp",
    "https://nginx.img2anime.click/static/demo/demo9.webp",
    "https://nginx.img2anime.click/static/demo/demo10.webp",
    "https://nginx.img2anime.click/static/demo/demo11.webp",
    "https://nginx.img2anime.click/static/demo/demo12.webp",
    "https://nginx.img2anime.click/static/demo/demo13.webp",
    "https://nginx.img2anime.click/static/demo/demo14.webp",
    "https://nginx.img2anime.click/static/demo/demo15.webp",
    "https://nginx.img2anime.click/static/demo/demo16.webp",
    "https://nginx.img2anime.click/static/demo/demo17.webp",
    "https://nginx.img2anime.click/static/demo/demo18.webp",
];
export const BANNER_IMGS = [
    {img_src:"https://nginx.img2anime.click/static/banner/banner1.webp", img_alt: "banner demo 1"},
    {img_src:"https://nginx.img2anime.click/static/banner/banner2.webp", img_alt: "banner demo 2"},
    {img_src:"https://nginx.img2anime.click/static/banner/banner3.webp", img_alt: "banner demo 3"},
    {img_src:"https://nginx.img2anime.click/static/banner/banner4.webp", img_alt: "banner demo 4"}
]
export const HEADER_LOGO = "https://nginx.img2anime.click/static/header_logo_img2anime.jpg";
export const PAYLOAD_IMG_MODEL = "anime_1";
export const SOCIAL_MEDIA = [{
    id: "Facebook",
    icon: facebook,
    qrCode: "",
    mobileUrl: "fb://page/taoanhAI199x",
    webURl: "https://www.facebook.com/taoanhAI199x/",
    style: "social-media-btn-fb"
},
{
    id: "Tik tok",
    icon: tiktok,
    qrCode: "",
    mobileUrl: "",
    webURl: "https://www.tiktok.com/@kaymangashop?_t=8k2fhOXGRW5&_r=1",
    style: "social-media-btn-tiktok"
},
]
//END WEB CONFIG CONST


// SHARED CONFIG
export const IMAGE_TYPE = {
    UPLOAD: "UPLOAD",
    TRANSFORM: "TRANSFORM",
    CLOSE: "CLOSE",
    NAVIGATE: "NAVIGATE"
}
export enum ErrorHttpStatusCode {
    NUMBER_400 = 400,
    NUMBER_401 = 401,
    NUMBER_403 = 403,
    NUMBER_404 = 404,
    NUMBER_422 = 422,
    NUMBER_500 = 500,
  }
export const JOB_PROGRESS_INTERVAL = 6000;
export const FAKE_PROGRESS_INTERVAL = 100;
export const QUEUE_JOB_PROGRESS_INTERVAL = 5000;
export const TOP_IMG_POSITION = 550;