import { useTranslation } from "react-i18next";
import { GALLERY_IMGS, QUICK_OVERVIEW_CONTENTS, SOCIAL_MEDIA, TOP_IMG_POSITION, WEB_URL, websiteHashTag } from "../../constant/constant";
import LazyLoad from 'react-lazyload';

const PortfolioComponent = () => {
	const { t: translate } = useTranslation();
	return (
		<div className="portfolio">
			<div className="container display-flex display-flex-col portfolio-container">
				<h1 className="quick-overview-title">{translate('quickOverview')}</h1>
				{
					QUICK_OVERVIEW_CONTENTS.map((item, index) => {
						const isLastItem = QUICK_OVERVIEW_CONTENTS.length - 1 === index;
						if ((index + 1) % 2 !== 0) {
							return (
								<div key={`-quick-overview-content-${index + 1}`}>
									<div className="qo-content">
										<div className="qo-content-text">
											<h2 className="qo-content-title" dangerouslySetInnerHTML={{ __html: translate(item.title) }}></h2>
											<p className="qo-content-odd" dangerouslySetInnerHTML={{ __html: translate(item.content) }}></p>
											<br />
											{isLastItem && <span className="hash-tag"><a href={WEB_URL}>{websiteHashTag}</a></span>}
										</div>
										<img src={item.img} alt={item.img_alt} />
									</div>
									<hr />
								</div>
							)
						} else {
							return (
								<div key={`-quick-overview-content-${index + 1}`}>
									<div className="qo-content">
										<img src={item.img} alt={item.img_alt} />
										<div className="qo-content-text">
											<h2 className="qo-content-title qo-content-title-even" dangerouslySetInnerHTML={{ __html: translate(item.title) }}></h2>
											<p className="qo-content-even" dangerouslySetInnerHTML={{ __html: translate(item.content) }}></p>
										</div>
									</div>
									<hr />
								</div>
							)
						}
					})
				}
				<h1 className="quick-overview-title">{translate('connectUs')}</h1>
				<div className="display-flex social-media-container">
					{
						SOCIAL_MEDIA.map(item => {
							const hrefUrl = window.innerWidth <= 1038 && item.mobileUrl ? item.mobileUrl : item.webURl;
							return (
								<a className={"btn social-media-btn " + item.style} href={hrefUrl} target="_blank" rel="noopener noreferrer">
									<div className="container social-media-icon-container">
									<img src={item.icon} className="social-media-icon" alt="contact us" />
									<span className="social-media-icon-id">{translate(item.id)}</span>
									</div>
								</a>
							)
						})
					}
				</div>
				<hr />
				<h1 className="quick-overview-title">{translate('quickOverviewGallery')}</h1>
				<p className="gallery-content" dangerouslySetInnerHTML={{ __html: translate("galleryContent") }}></p>
				<div className="gallery-container">
					{GALLERY_IMGS.map((item, index) => {
						return (
							<div className="gallery-item" key={`gallery-item-${index + 1}`}>
								<LazyLoad
									height={100}
									offset={[-100, 100]}>
									<img className="gallery-img" src={item} alt={`demo art ${index + 1}`} />
								</LazyLoad>
								{(index + 1) % 3 === 0 && (
									<div>
										<hr className="gallery-hr" />
										<button className="gallery-try-btn" onClick={() => { window.scroll({ top: TOP_IMG_POSITION, left: 0, behavior: 'smooth' }) }}>{translate('tryitout')}</button>
										<hr className="gallery-hr" />
									</div>

								)}
							</div>
						)
					})}
				</div>
			</div>
			<div className="container display-flex display-flex-col question portfolio-container">
				<h1>{translate('whatyoulooklike')}</h1>
				<button className="try-btn" onClick={() => { window.scroll({ top: TOP_IMG_POSITION, left: 0, behavior: 'smooth' }) }}>{translate('tryitout')}</button>
			</div>
		</div>
	);
}

export default PortfolioComponent;
